import React, { FC, useEffect, useRef } from 'react';

type OverlayProps = {
    show: boolean,
    close: () => void,
    children?: JSX.Element | JSX.Element[]
}

const OverlayView: FC<OverlayProps> = ({ show, close, children }) => {
    const node = useRef<HTMLDivElement>(null);

    useEffect(() => {
        document.addEventListener('keydown', handleEscape, false);
        return () => document.removeEventListener('keydown', handleEscape, false);
    }, []);

    const handleClick = (e: any) => {
        e.stopPropagation();
        if (node.current!.contains(e.target)) {
            return
        }
        else {
            close();
        }
    }

    const handleEscape = (e: { key: any; keyCode: any; }) => {
        let key = e.key || e.keyCode;
        if (key === 'Escape' || key === 27) {
            close();
        }
    }

    return (
        <div onClick={handleClick} className={`map-overlay ${show ? 'display-block' : 'display-none'}`}>
            <section ref={node} className='map-overlay-main'>
                {children}
            </section>
        </div>
    );
}

export default OverlayView;