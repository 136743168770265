import { GatsbyImage } from 'gatsby-plugin-image';
import React, { FC, useEffect } from 'react';
import { TripDay } from '../Trips';

import './TimelineRetry.css';

type TimelineProps = {
    days: TripDay[] // TODO: determine type later
}

const TimelineRetry: FC<TimelineProps> = ({ days }) => {

    useEffect(() => {
        const items = document.querySelectorAll('.timeline-section li');

        const isElementCenteredInViewport = (el: Element) => {
            const { top, bottom } = el.getBoundingClientRect();
            const mid = (top + bottom) / 2;

            const vHeight = (window.innerHeight || document.documentElement.clientHeight);

            return (top > 0 || bottom > 0) && mid <= (vHeight / 2) + 40;
        }

        const onScroll = (el: Element) => {
            const { top } = el.getBoundingClientRect();
            const offset = top - document.body.getBoundingClientRect().top;
            const vHeight = (window.innerHeight || document.documentElement.clientHeight);
            const elHeight = el.clientHeight;
            const pageYOffset = window.pageYOffset || (document.documentElement || document.body.parentNode || document.body).scrollTop;
            const scrollDistance = pageYOffset - offset + vHeight / 2;
            const percentage = scrollDistance / elHeight * 100;

            if (scrollDistance > 0) {
                (el as HTMLElement).style.setProperty('--timeline-fill-height', `${percentage}%`);
            } else {
                (el as HTMLElement).style.setProperty('--timeline-fill-height', `0px`);
            }

            if (window.pageYOffset + window.innerHeight >= document.body.scrollHeight) {
                const intervalId = setInterval(() => {
                    const currentHeight = (el as HTMLElement).style.getPropertyValue('--timeline-fill-height');
                    const remaining = 100 - parseFloat(currentHeight);

                    if (remaining <= 0) {
                        clearInterval(intervalId);
                    }

                    (el as HTMLElement).style.setProperty('--timeline-fill-height', `${parseFloat(currentHeight) + 1}%`);
                }, 5);
            }
        }

        const scrollIndicator = () => {
            for (let i = 0; i < items.length; i++) {
                onScroll(items[i]);
            }
        }

        const nodeIndicator = () => {
            for (let i = 0; i < items.length; i++) {
                if (isElementCenteredInViewport(items[i])) {
                    items[i].classList.add('slide-in');
                } else if (window.pageYOffset + window.innerHeight >= document.body.scrollHeight) {
                    items[i].classList.add('slide-in');
                } else {
                    items[i].classList.remove('slide-in');
                }
            }
        }

        ['load', 'scroll', 'resize'].forEach(event => {
            window.addEventListener(event, scrollIndicator);
            window.addEventListener(event, nodeIndicator);
        });

        return () => {
            ['load', 'scroll', 'resize'].forEach(event => {
                window.removeEventListener(event, scrollIndicator);
                window.removeEventListener(event, nodeIndicator);
            });
        }
    });

    return (
        <section className='timeline-section'>
            <ul>
                {days.map(day => (
                    <li key={day.title}>
                        <span>
                            <div>
                                <p className='timeline-title'>{day.title}</p>
                                <p className='timeline-subtitle'>{day.subTitle}</p>
                                <p className='timeline-description'>{day.description}</p>
                                <main className='row'>
                                        {day.keywords.map(keyword => (
                                            <section key={keyword} className='timeline-keyword'>
                                                <p id='checkmark' />
                                                <>{keyword}</>
                                            </section>
                                        ))}
                                </main>
                            </div>
                            <>{day.image ? <GatsbyImage alt={`timeline-image-${day.title}`} image={day.image} /> : <div style={{ visibility: 'hidden' }} />}</>
                        </span>
                    </li>
                ))}
                {/* <div style={{ position: 'absolute', top: 0, left: '50%', transform: 'translateX(50%)', bottom: 24, width: 10, height: 100, background: 'red' }} /> */}
            </ul>
        </section>
    );
}

export default TimelineRetry;