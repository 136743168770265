import React, { FC, useState } from 'react';
import { GatsbyImage, IGatsbyImageData, StaticImage } from 'gatsby-plugin-image';
import { default as Overlay } from './OverlayView';

import './Map.css';

export type MapMarker = {
    name: string,
    description: string,
    image: IGatsbyImageData,
    top: number,
    left: number
};

type MapProps = {
    markers: MapMarker[];
};

const MapView: FC<MapProps> = ({ markers }) => {
    const [selectedMarker, setSelectedMarker] = useState<MapMarker>();

    return (
        <div className='map-container'>
            <div className='map-background'>
                <div className='map'>
                    <StaticImage
                        src='../../images/Map.jpg'
                        quality={95}
                        formats={['auto', 'webp', 'avif']}
                        loading='eager'
                        alt='Galapagos map'
                    />

                    <div>
                        {markers.map(marker => (
                            <div
                                key={marker.name}
                                className='map-marker'
                                style={{ top: `${marker.top}%`, left: `${marker.left}%` }}
                                onClick={() => setSelectedMarker(marker)}
                            />
                        ))}
                    </div>

                    <Overlay show={!!selectedMarker} close={() => setSelectedMarker(undefined)}>
                        {selectedMarker &&
                            <div>
                                <div className='map-overlay-image-container'>
                                    <GatsbyImage className='map-overlay-image' alt='map-overlay-image' image={selectedMarker.image}></GatsbyImage>
                                    <div className='map-overlay-gradient' />
                                </div>

                                <div className='map-overlay-text-container'>
                                    <p className='text_header'>{selectedMarker.name}</p>
                                    <p className='text_content'>{selectedMarker.description}</p>
                                </div>
                            </div>
                        }
                    </Overlay>
                </div>
            </div>

            {['left', 'right'].map(direction => (
                <div key={direction} className={`map-palm-leaf ${direction}`}>
                    <StaticImage
                        src='../../images/Palmleaf.png'
                        quality={95}
                        formats={['auto', 'webp', 'avif']}
                        loading='eager'
                        alt='map-view-palm-leaf'
                    />
                </div>
            ))}
        </div>
    );
}

export default MapView;