import React, { FC } from 'react';

import { Meta, TimelineRetry } from './components';
import { TripType } from './Trips';

import './Timeline.css';

type TimelineProps = {
    trip: TripType;
};

const TimelineView: FC<TimelineProps> = ({ trip }) => {

    return (
        <div className='timeline'>
            <Meta meta={trip.metaData} />
            <div style={{ marginTop: -20 }}><TimelineRetry days={trip.days} /></div>
            
        </div>
    );
}

export default TimelineView;