import React, { FC } from 'react';
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image';

import { TripMetaData } from '../Trips';
import { useTranslation } from 'react-i18next';

type MetaProps = {
    meta: TripMetaData
};

// TODO: add image orientation?

const MetaView: FC<MetaProps> = ({ meta }) => {
    const { t } = useTranslation('translation', { keyPrefix: 'tripBlock' });
    return (
        <div className='meta-container'>
            <div className='meta-body'>
                <div className='meta-image-container'>
                    <GatsbyImage className='meta-image' alt='' image={meta.image} />
                </div>

                <div className='meta-content'>
                    <div>
                        <div className='timeline-title'>{meta.title}</div>

                        {meta.keywords.map(keyword => (
                            <div key={keyword} className='timeline-keyword'>
                                <span id='checkmark' />
                                <span>{keyword}</span>
                            </div>
                        ))}

                        <p className='timeline-description'>{meta.description}</p>
                        <p style={{marginTop: '70px'}} className='timeline-description'>{t('text')}</p>
                    </div>

                    <div className='row'>
                        <div className='row align-center'>
                            <StaticImage
                                src='../../../icons/clock.svg'
                                quality={95}
                                formats={['auto', 'webp', 'avif']}
                                loading='eager'
                                alt='Intro banner'
                                className='custom_icon'
                            />
                            <div className='timeline-keyword'>{t('reisduur') +`: ${meta.durationInDays} ` + t('dagen')}</div>
                        </div>

                        <div style={{ width: 20 }} />

                        <div className='row align-center'>
                            <StaticImage
                                src='../../../icons/money.svg'
                                quality={95}
                                formats={['auto', 'webp', 'avif']}
                                loading='eager'
                                alt='Intro banner'
                                className='custom_icon'
                            />
                            <div className='timeline-keyword'>{t('vanaf') + `: ${meta.price}`}</div>
                        </div>
                    </div>
                </div>

                <div className='palmleaf-icon right'>
                    <StaticImage
                        src='../../../images/Palmleaf.png'
                        quality={95}
                        formats={['auto', 'webp', 'avif']}
                        loading='eager'
                        alt='palmleaf'
                    />
                </div>
            </div>
        </div>
    );
}

export default MetaView;